import { InputHTMLAttributes, useEffect, useState } from "react";
import { classes } from "../../lib/utils";
import styles from "./Input.module.css";
import { OnInputChange, OnInputChangeCB } from "./onChange";

export interface InputShortTextProps
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    "onChange" | "defaultChecked" | "type"
  > {
  id: string;
  name: string;
  defaultValue?: InputHTMLAttributes<HTMLInputElement>["defaultValue"];

  onChange?: OnInputChangeCB;

  theme?: {
    input?: string;
  };
}

export const InputShortText = ({
  id,
  name,
  defaultValue,
  onChange,
  theme,
  ...props
}: InputShortTextProps) => {
  const [value, setValue] = useState(defaultValue ?? "");

  useEffect(() => {
    setValue(defaultValue ?? "");
  }, [defaultValue]);

  return (
    <input
      {...props}
      type="text"
      id={id}
      value={value}
      name={name}
      onChange={OnInputChange(onChange, value, setValue)}
      className={classes(styles.input, theme?.input, props?.className)}
    />
  );
};
